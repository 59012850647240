<script>
import { computed } from "vue";
import { useStore } from "vuex";
import InlineSvg from "vue-inline-svg";
export default {
  setup() {
    const store = useStore();
    const categories = computed(() => store.state.indicesList);

    return {
      categories,
    };
  },
  components: { InlineSvg },
};
</script>
<template>
  <!-- breadcrumb -->
  <div class="breadcrumb">
    <div class="container">
      <ul>
        <li><router-link to="/">Inicio</router-link></li>
        <li>Estructura</li>
      </ul>
    </div>
  </div>
  <section class="pb-4">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <h1 class="mb-4 pb-4">Estructura del SINGE</h1>
        </div>
      </div>
    </div>
  </section>
  <section class="sg_subcontent pt-2">
    <!-- categorías-->
    <div class="container mb-4 pb-4">
      <div class="row">
        <div class="col-12">
          <h2 class="mt-4 pt-4">
            Módulos y componentes del <strong>SINGE</strong>
          </h2>
        </div>
      </div>
    </div>
    <div v-if="categories">
      <div v-for="category of categories" :key="`cat-item-${category.id}`">
        <router-link
          :to="{ name: 'Indices', params: { category: category.slug } }"
          class="sg_link_cat"
        >
          <div class="container">
            <div class="row">
              <div class="col-3 col-sm-2 text-center">
                <div class="svg_icon">
                  <inline-svg
                    :src="require('../components/icons/vida-v2.svg')"
                  />
                </div>
              </div>
              <div class="col-9 col-sm-10">
                {{ category.name }}

                <div v-if="category.categories.length > 0">
                  <span
                    ><span class="cat_title mr-1">Categoría:</span>
                    <span
                      v-for="category of category.categories"
                      :key="`cat-item-${category.id}`"
                      class="cat"
                    >
                      {{ category.name }}
                    </span>
                  </span>
                </div>
                <div v-else>
                  <span
                    ><span class="cat_title">Categoría:</span>
                    <span class="cat">Sin categorías</span></span
                  >
                </div>
                <span
                  ><strong
                    >{{ category.indices.length }} indicadores</strong
                  ></span
                >
                <span>{{ category.description }}</span>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </section>
</template>
